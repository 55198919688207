.navbar{
    margin-bottom: 30px;
}
.list-card{
    overflow-y: auto;
    height: 80vh !important;
}
.nav-logo{
    height: 30px;
}
.nav-link{
    font-size: 16px !important;
}
.ct-flex-bottom {
    margin-bottom: 50px !important;
}
body{
    background-color: #f8f9fa !important;
}
.ProcessingDiv {
    top: 0 !important;
    bottom: 0 !important;
    width: 100%;
    height: 100vh;
    background: white;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10000 !important;
}

.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 85vh;
}


.loader-size {
    width: 180px;
}


.list-img{
    width: auto;
    height: 40px;
}